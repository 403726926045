import React, { useRef, useState, useEffect } from 'react'
import Web3 from 'web3'

import './Normalize.css';
import './App.css';
// import { ReactComponent as Metamask } from './images/metamask.svg';
import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as LogoDefiYield } from './images/logo.bb7ed4c0.svg';
import audioSrc from './media/sandman.mp3';

// const { ethereum } = window as any;

const tokens = {
  morpheus: {
    address: '0x0D30a539F0597F01cA34356515318a4Fb6Ffe7CB',
    symbol: 'MORPHEUS',
    decimals: 18,
    image: 'https://sandman.farm/images/morpheus.png',
  },
  sandman: {
    address: '0xf9b4dEFdDe04fe18F5ee6456607F8A2eC9fF6A75',
    symbol: 'SANDMAN',
    decimals: 18,
    image: 'https://sandman.farm/images/sandman.png',
  },
  sandmanMatic: {
    address: '0x3cd18d001a9152b3157A18C3cAe7b7F4b7e1977B',
    symbol: 'SAND-MATIC',
    decimals: 18,
    image: 'https://sandman.farm/images/sandmanmatic.png',
  },
  sandmanUsdc: {
    address: '0x56de3448C9EE1ADCb05F26f5093b593D492695c8',
    symbol: 'SAND-USDC',
    decimals: 18,
    image: 'https://sandman.farm/images/sandmanusdc.png',
  },
};

const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false)

  const audioRef = useRef(new Audio(audioSrc))
  // audioRef.current.autoplay = true OPTIONAL
  audioRef.current.loop = true

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play()
    } else {
      audioRef.current.pause()
    }
  }, [isPlaying])

  return (
    <a key="music" href="#" role="button" className={isPlaying ? 'playing' : null} onClick={(evt) => { evt.preventDefault(); setIsPlaying(!isPlaying); }}>
      <svg id="music-icon" width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="music" className="svg-inline--fa fa-music fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor" d="M470.38 1.51L150.41 96A32 32 0 0 0 128 126.51v261.41A139 139 0 0 0 96 384c-53 0-96 28.66-96 64s43 64 96 64 96-28.66 96-64V214.32l256-75v184.61a138.4 138.4 0 0 0-32-3.93c-53 0-96 28.66-96 64s43 64 96 64 96-28.65 96-64V32a32 32 0 0 0-41.62-30.49z" />
      </svg>
    </a>
  )
}

function App() {
  const addToken = (evt) => {
    evt.preventDefault();
    var target = evt.target;

    if (!window.ethereum) {
      alert('Please install metamask plugin in your browser');
      return;
    }

    window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokens[target.dataset.token].address,
          symbol: tokens[target.dataset.token].symbol,
          decimals: tokens[target.dataset.token].decimals,
          image: tokens[target.dataset.token].image,
        },
      },
    });
  }

  const addNetwork = async (evt) => {
    evt.preventDefault();

    if (!window.ethereum) {
      alert('Please install metamask plugin in your browser');
      return;
    }

    const params = [{
        chainId: '0x89',
        chainName: 'Matic Mainnet',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18
        },
        rpcUrls: ['https://rpc-mainnet.maticvigil.com/'],
        blockExplorerUrls: ['https://polygonscan.com/']
      }]

    const web3 = new Web3(window.ethereum)

    const network = await web3.eth.net.getId();

    if (network.toString() === '137') {
      alert("Polygon Network has already been added to Metamask.");
      return;
    }

    window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params
    })
    .then(() => console.log('Success'))
    .catch((error) => console.log("Error", error.message));
  }

  return (
    <main>
      <h1>
        <Logo />
      </h1>
      <footer>
        <div className="audit">
          <a href="https://rugdoc.io/project/sandman/" className="rugdoc" target="_blank">
            <span className="badges">KYC / LOW RISK</span>
            <img src="https://rugdoc.io/assets/2021/06/rugdoc-review-badge-with-glow.png" width="200" />
          </a>
        </div>
        <div className="btn-group">
          <a href="#" className="btn add-token" data-token="morpheus" onClick={addToken}>
            MORPHEUS
          </a>
          <a href="#" className="btn add-token" data-token="sandman" onClick={addToken}>
            SANDMAN
          </a>
          <a href="#" className="btn add-token" data-token="sandmanMatic" onClick={addToken}>
            SANDMAN-MATIC
          </a>
          <a href="#" className="btn add-token" data-token="sandmanUsdc" onClick={addToken}>
            SANDMAN-USDC
          </a>
          <a href="#" className="btn" id="add-network" onClick={addNetwork}>
            {/* <Metamask width="32" height="32" /> */}
            <svg width="24" height="24" viewBox="0 0 125 125">
              <defs>
                <clipPath id="clip-path">
                  <rect id="Rectangle_32" data-name="Rectangle 32" width="125" height="125" transform="translate(385 345)" fill="#fff"/>
                </clipPath>
              </defs>
              <g id="favicon" transform="translate(-385 -345)">
                <g id="Mask_Group_3" data-name="Mask Group 3" clip-path="url(#clip-path)">
                  <path id="Path_151" data-name="Path 151" d="M91.487,31.85a7.794,7.794,0,0,0-7.605,0L66.434,41.97l-11.855,6.6L37.132,58.69a7.8,7.8,0,0,1-7.605,0L15.658,50.77a7.566,7.566,0,0,1-3.8-6.38V28.77a7.114,7.114,0,0,1,3.8-6.38l13.645-7.7a7.8,7.8,0,0,1,7.605,0l13.645,7.7a7.566,7.566,0,0,1,3.8,6.38V38.89l11.855-6.82V21.95a7.114,7.114,0,0,0-3.8-6.38L37.132,1.051a7.8,7.8,0,0,0-7.605,0L3.8,15.571A7.114,7.114,0,0,0,0,21.95V51.21a7.114,7.114,0,0,0,3.8,6.38l25.724,14.52a7.8,7.8,0,0,0,7.605,0l17.447-9.9,11.855-6.82,17.448-9.9a7.794,7.794,0,0,1,7.605,0l13.645,7.7a7.567,7.567,0,0,1,3.8,6.38v15.62a7.114,7.114,0,0,1-3.8,6.38l-13.645,7.92a7.8,7.8,0,0,1-7.605,0l-13.645-7.7a7.567,7.567,0,0,1-3.8-6.38V65.289l-11.855,6.82v10.12a7.114,7.114,0,0,0,3.8,6.38l25.724,14.52a7.8,7.8,0,0,0,7.605,0l25.724-14.52a7.567,7.567,0,0,0,3.8-6.38V52.97a7.114,7.114,0,0,0-3.8-6.38Z" transform="translate(387 354.939)" fill="#8247e5"/>
                </g>
              </g>
            </svg>

            Add Polygon Network
          </a>
          <div className="toolbar">
            <AudioPlayer />
            <a href="https://github.com/sandmanfinance" target="_blank">
              <svg width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="github" className="svg-inline--fa fa-github fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><path fill="currentColor" d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" /></svg>
            </a>
            <a href="https://docs.sandman.farm/" target="_blank">
              <svg width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="book" className="svg-inline--fa fa-book fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M448 360V24c0-13.3-10.7-24-24-24H96C43 0 0 43 0 96v320c0 53 43 96 96 96h328c13.3 0 24-10.7 24-24v-16c0-7.5-3.5-14.3-8.9-18.7-4.2-15.4-4.2-59.3 0-74.7 5.4-4.3 8.9-11.1 8.9-18.6zM128 134c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm0 64c0-3.3 2.7-6 6-6h212c3.3 0 6 2.7 6 6v20c0 3.3-2.7 6-6 6H134c-3.3 0-6-2.7-6-6v-20zm253.4 250H96c-17.7 0-32-14.3-32-32 0-17.6 14.4-32 32-32h285.4c-1.9 17.1-1.9 46.9 0 64z" /></svg>
            </a>
            <a href="https://twitter.com/sandman_finance" target="_blank">
              <svg width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className="svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" /></svg>
            </a>
            <a href="https://t.me/SandMan_Finance" target="_blank">
              <svg width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="telegram-plane" className="svg-inline--fa fa-telegram-plane fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z" /></svg>
            </a>
            <a href="https://sandmanfinance.medium.com/" target="_blank">
              <svg width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="medium-m" className="svg-inline--fa fa-medium-m fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M71.5 142.3c.6-5.9-1.7-11.8-6.1-15.8L20.3 72.1V64h140.2l108.4 237.7L364.2 64h133.7v8.1l-38.6 37c-3.3 2.5-5 6.7-4.3 10.8v272c-.7 4.1 1 8.3 4.3 10.8l37.7 37v8.1H307.3v-8.1l39.1-37.9c3.8-3.8 3.8-5 3.8-10.8V171.2L241.5 447.1h-14.7L100.4 171.2v184.9c-1.1 7.8 1.5 15.6 7 21.2l50.8 61.6v8.1h-144v-8L65 377.3c5.4-5.6 7.9-13.5 6.5-21.2V142.3z" /></svg>
            </a>
            <a href="https://www.youtube.com/channel/UCN_deX0boZPVUre-SV6aqwA" target="_blank">
              <svg width="24" height="24" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" className="svg-inline--fa fa-youtube fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" /></svg>
            </a>
          </div>
          <div class="audit-mobile">
            <a href="https://rugdoc.io/project/sandman/" target="_blank" class="audit-mobile-link">
              RugDoc
              <span class="kyc">KYC</span>/<span class="low-risk">LOW RISK</span>
            </a>
            <a href="https://paladinsec.co/projects/sandman/" class="audit-mobile-link" target="_blank">
              PALADIN AUDITED
            </a>
            {/* <a href="#" class="audit-mobile-link" target="_blank">
              DEFIYIELD AUDITED
            </a> */}
          </div>
        </div>
        <div className="audit" style={{ flexDirection: 'column' }}>
          <a href="https://paladinsec.co/projects/sandman/" className="paladin" target="_blank">
            <img src="https://paladinsec.co/pld/assets/audited-by-paladin-long.svg" width="200" />
          </a>
          {/* <a href="#" className="defiyield" target="_blank">
            <LogoDefiYield />
          </a> */}
        </div>
      </footer>
    </main>
  );
}

export default App;
